import * as DOMPurify from "dompurify";
import * as React from "react";

import { ActionButton, DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import {
  ChoiceGroup,
  IPeoplePickerItemSelectedProps,
  IPersonaProps,
  Icon,
  Label,
  Modal,
  PeoplePickerItem,
  Spinner,
  TooltipHost,
} from "@fluentui/react";
import {
  FluentProvider,
  Input,
  Link,
  Persona,
  SelectTabEvent,
  Tab,
  TabList,
  Textarea,
} from "@fluentui/react-components";
import { NormalPeoplePicker, ValidationState } from "@fluentui/react/lib/Pickers";

import { FilePicker } from "../_filePicker/FilePicker";
import Moment from "moment";
import ReactDOM from "react-dom";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import i18n from "../../i18n";
import { userService } from "../../_services/user.service";

export interface Props {
  allCardsCollapsed: boolean;
  item: any;
  userData: any;
}

export interface States {
  collapsed: boolean;
  message: string;
  item: any;
  possibleContacts: any[];
  currentContacts: any[];
  contactsCC: any[];
  contactsBCC: any[];
  replyTo: any[];
  replyToAll: any[];
  replyCcAll: any[];
  showCurrentContacts: boolean;
  loadingEntities: boolean;
  loading: boolean;
  page: number;
  totalPages: number;
  editingMessage: string;
  editMessage: string;
  markdown: any;
  tabSelected: string;
  markdownNew: any;
  tabSelectedNew: string;
  showReply: boolean;
  showCC: boolean;
  showBCC: boolean;
  attachments: any[];
  attachmentsInline: any[];
  attachmentsOutline: any[];
  savingAttachment: boolean;
}

export class ConversationViewer extends React.Component<Props, States> {
  private _isMounted: boolean;
  private throttleTimer: boolean;
  private loadingMoreResults: boolean;
  private listener: boolean = false;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.throttleTimer = false;
    this.loadingMoreResults = false;
    this.props = props;

    this.state = {
      collapsed: this.props.allCardsCollapsed,
      message: "",
      item: this.props.item,
      possibleContacts: this.props.possibleContacts,
      currentContacts: [],
      contactsCC: [],
      contactsBCC: [],
      replyTo: [],
      replyToAll: [],
      replyCcAll: [],
      showCurrentContacts: false,
      loadingEntities: true,
      loading: true,
      page: this.props.item.page,
      totalPages: this.props.item.totalPages || 0,
      editingMessage: "",
      editMessage: "",
      markdown: null,
      tabSelected: "write",
      markdownNew: null,
      tabSelectedNew: "write",
      showReply: false,
      showCC: false,
      showBCC: false,
      attachments: [],
      attachmentsInline: [],
      attachmentsOutline: [],
      savingAttachment: false,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({ loading: false });
    const params = new URLSearchParams(document.location.search);
    let messageId = params.get("messageId") || params.get("message") || null;
    if (this.state.possibleContacts && this.state.possibleContacts.length > 0) {
      this._isMounted &&
        this.setState({ loadingEntities: false, loading: false }, () => {
          if (this.props.target || messageId)
            document.getElementById(messageId || this.props.target)?.scrollIntoView(true);
        });
    } else {
      userService
        .getRepoUsers("ADMIN")
        .then((response) => {
          let admins = response.data;
          for (let i = 0; i < admins.length; i++) {
            admins[i].key = admins[i].id;
            admins[i].text = admins[i].name + (admins[i].surname ? " " + admins[i].surname : "");
          }
          userService
            .getRepoUsers("INTERNAL")
            .then((response) => {
              let contacts = response.data;
              for (let i = 0; i < contacts.length; i++) {
                contacts[i].key = contacts[i].id;
                contacts[i].text = contacts[i].name + (contacts[i].surname ? " " + contacts[i].surname : "");
              }
              this.setState({ possibleContacts: contacts.concat(admins) });
              this._isMounted &&
                this.setState({ loadingEntities: false, loading: false }, () => {
                  if (this.props.target || messageId)
                    document.getElementById(messageId || this.props.target)?.scrollIntoView(true);
                });
            })
            .catch(() => {
              this._isMounted && this.setState({ loadingEntities: false, loading: false });
            });
        })
        .catch(() => {
          this._isMounted && this.setState({ loadingEntities: false, loading: false });
        });
    }
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({ collapsed: this.props.allCardsCollapsed });
    }
    if (this.props.item !== prevProps.item) {
      this._isMounted &&
        this.setState({ item: this.props.item, totalPages: this.props.item.totalPages, page: this.props.item.page });
    }
    if (this.state.loading !== prevState.loading && !this.listener) {
      document.getElementById("main-view")?.addEventListener("scroll", this.handleInfiniteScroll);
      this.listener = true;
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.getElementById("main-view")?.removeEventListener("scroll", this.handleInfiniteScroll, false);
    this.listener = false;
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    });

    return user ? (user.name || "") + " " + (user.surname || "") : "";
  }

  private getContactNameTooltip(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    });

    return user ? (
      <TooltipHost
        content={
          <div className="m-1">
            <FluentProvider>
              <p className="font-weight-bold" style={{ fontSize: "14px" }}>
                {i18n.t("app:Contact")}
              </p>
              <Persona
                className="mb-2"
                name={(user.name || "") + " " + (user.surname || "")}
                secondaryText={user.email}
                size="medium"
                textAlignment="center"
                avatar={{ color: "colorful" }}
              />
            </FluentProvider>
          </div>
        }
      >
        <span>{(user.name || "") + " " + (user.surname || "")}</span>
      </TooltipHost>
    ) : (
      ""
    );
  }

  private getPersona(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    });
    return (
      <TooltipHost
        content={
          <div className="m-1">
            <FluentProvider>
              <p className="font-weight-bold" style={{ fontSize: "14px" }}>
                {i18n.t("app:Contact")}
              </p>
              <Persona
                className="mb-2"
                name={(user?.name || "") + " " + (user?.surname || "")}
                secondaryText={user?.email || ""}
                size="medium"
                textAlignment="center"
                avatar={{ color: "colorful" }}
              />
            </FluentProvider>
          </div>
        }
      >
        <Persona
          className="pr-2"
          size="extra-small"
          name={(user?.name || "") + " " + (user?.surname || "")}
          textAlignment="center"
          avatar={{ color: "colorful" }}
        />
      </TooltipHost>
    );
  }

  private loadNextPage() {
    if (this.state.page < this.state.totalPages - 1) {
      let page = this.state.page;
      page += 1;
      this.loadingMoreResults = true;
      userService.getConversationMessages(this.state.item.id, page).then((response) => {
        let messages: any = response.data.content;
        let requests: any = [];
        for (let i in messages) {
          for (let j in messages[i].attachments) {
            if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
              requests.push(
                userService
                  .downloadConversationAttachment(this.state.item.id, messages[i].id, messages[i].attachments[j].id)
                  .then((response) => {
                    const byteArray = new Uint8Array(response.data);
                    const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                    const url = URL.createObjectURL(blob);
                    messages[i].attachments[j].blob = url;
                  })
                  .catch((error) => {
                    console.log(error);
                  })
              );
            }
          }
        }
        Promise.all(requests).then(() => {
          let item = this.state.item;
          let userIds: string[] = [];
          let totalPages = response.data.totalPages;
          for (let i in messages) {
            if (!userIds.includes(messages[i].createdByUserId)) {
              userIds.push(messages[i].createdByUserId);
            }
          }
          this.setState({ page: page, totalPages: totalPages });
          item.messages = item.messages.concat(messages);
          item.totalPages = totalPages;
          item.page = page;
          this.props.updateConversation(item);
          this.loadingMoreResults = false;
        });
      });
    }
  }

  private throttle = (callback, time) => {
    if (this.throttleTimer) return;
    this.throttleTimer = true;
    setTimeout(() => {
      callback();
      this.throttleTimer = false;
    }, time);
  };

  private handleInfiniteScroll = () => {
    this.throttle(() => {
      const endOfPage =
        (document.getElementById("main-view")?.offsetHeight || 0) +
          (document.getElementById("main-view")?.scrollTop || 0) >=
        (document.getElementById("main-view-conversation")?.offsetHeight || 0) - 300;
      if (endOfPage) {
        this.loadNextPage();
      }
    }, 500);
  };

  private _reply() {
    let to = this.state.currentContacts.map((item) => {
      if (item.id && item.email) return item.email;
      else return item.key;
    });
    let cc = this.state.contactsCC.map((item) => {
      if (item.id && item.email) return item.email;
      else return item.key;
    });
    let bcc = this.state.contactsBCC.map((item) => {
      if (item.id && item.email) return item.email;
      else return item.key;
    });
    let attachments: string[] = [];
    for (let i in this.state.attachments) {
      if (this.state.message.includes("(" + this.state.attachments[i] + ")")) {
        attachments.push(this.state.attachments[i]);
      }
    }
    attachments = attachments.concat(
      this.state.attachmentsOutline.map((a) => {
        return a.id;
      })
    );
    userService
      .createMessage(this.state.item.id, this.state.message, to, cc, bcc, attachments)
      .then((response) => {
        let item = this.state.item;
        let message = response.data;
        let requests: any = [];
        for (let j in message.attachments) {
          if (message.attachments[j] && message.attachments[j].inline) {
            requests.push(
              userService
                .downloadConversationAttachment(item.id, message.id, message.attachments[j].id)
                .then((response) => {
                  const byteArray = new Uint8Array(response.data);
                  const blob = new Blob([byteArray], { type: message.attachments[j].contentType });
                  const url = URL.createObjectURL(blob);
                  message.attachments[j].blob = url;
                })
                .catch((error) => {
                  console.log(error);
                })
            );
          }
        }
        Promise.all(requests).then(() => {
          if (!item.messages) item.messages = [];
          item.messages.push(message);
          this.props.updateConversation(item);
          this._isMounted &&
            this.setState({
              showReply: false,
              message: "",
              currentContacts: [],
              contactsCC: [],
              contactsBCC: [],
              tabSelectedNew: "write",
              markdownNew: null,
              attachments: [],
              attachmentsInline: [],
              attachmentsOutline: [],
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private _updateMessage(message, id) {
    let attachments: string[] = [];
    for (let i in this.state.attachments) {
      if (this.state.editMessage.includes("(" + this.state.attachments[i] + ")")) {
        attachments.push(this.state.attachments[i]);
      }
    }
    for (let i in message.attachments) {
      if (this.state.editMessage.includes("(" + message.attachments[i].id + ")")) {
        attachments.push(message.attachments[i].id);
      }
    }
    attachments = attachments.concat(
      this.state.attachmentsOutline.map((a) => {
        return a.id;
      })
    );
    userService
      .updateMessage(this.state.item.id, id, this.state.editMessage, attachments)
      .then((response) => {
        let item = this.state.item;
        for (let i in item.messages) {
          if (item.messages[i].id === id) {
            item.messages[i] = response.data;
            let requests: any = [];
            for (let j in item.messages[i].attachments) {
              if (item.messages[i].attachments[j] && item.messages[i].attachments[j].inline) {
                requests.push(
                  userService
                    .downloadConversationAttachment(item.id, item.messages[i].id, item.messages[i].attachments[j].id)
                    .then((response) => {
                      const byteArray = new Uint8Array(response.data);
                      const blob = new Blob([byteArray], { type: item.messages[i].attachments[j].contentType });
                      const url = URL.createObjectURL(blob);
                      item.messages[i].attachments[j].blob = url;
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                );
              }
            }
            Promise.all(requests).then(() => {
              this._closeEditMode(id);
              this.props.updateConversation(item);
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private _deleteMessage(id) {
    userService
      .deleteMessage(this.state.item.id, id)
      .then(() => {
        let item = this.state.item;
        item.messages = item.messages.filter((x) => {
          return x.id !== id;
        });
        this.props.updateConversation(item);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private _openEditMode(id) {
    let message = this.state.item.messages.find((item) => {
      return item.id === id;
    });
    this._isMounted &&
      this.setState({
        editingMessage: id,
        editMessage: message.content,
        attachments: [],
        attachmentsInline: [],
        attachmentsOutline: message.attachments.filter((a) => {
          return !a.inline;
        }),
        showReply: false,
      });
  }

  private _closeEditMode(id) {
    let message = this.state.item.messages.find((item) => {
      return item.id === id;
    });
    this._isMounted &&
      this.setState({
        editingMessage: "",
        editMessage: message.content,
        attachments: [],
        attachmentsInline: [],
        attachmentsOutline: [],
      });
  }

  private convertToMarkdown(item, message = "") {
    try {
      var MarkdownIt = require("markdown-it");
      var emoji = require("markdown-it-emoji");
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(message));
      const attachmentsInline = item.attachments.filter((a) => {
        return a.inline;
      });
      for (let i in attachmentsInline) {
        var replace = 'src="' + attachmentsInline[i].id + '"';
        result = result.replace(replace, 'src="' + attachmentsInline[i].blob + '"');
      }
      let markdown: any = { __html: result };
      return markdown;
    } catch {
      return message;
    }
  }

  private getMarkdown(messageId: string) {
    var MarkdownIt = require("markdown-it");
    var emoji = require("markdown-it-emoji");
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(this.state.editMessage || ""));

    const { attachmentsInline, item } = this.state;
    let message = item.messages.find((m) => {
      return m.id === messageId;
    });
    if (message) {
      console.log(message.attachments);
      for (let i in message.attachments) {
        var replace = 'src="' + message.attachments[i].id + '"';
        result = result.replace(replace, 'src="' + message.attachments[i].blob + '"');
      }
    }
    for (let i in attachmentsInline) {
      var replace = 'src="' + attachmentsInline[i].id + '"';
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"');
    }

    let markdown = { __html: result };
    this.setState({ markdown: markdown });
  }

  private _handleTabSelect = (e: SelectTabEvent, data: any, id: string): void => {
    if (data.value === "preview" && this.state.tabSelected === "write") {
      this.getMarkdown(id);
    }
    this._isMounted &&
      this.setState({
        tabSelected: data.value,
      });
  };

  private getMarkdownNew() {
    let message = this.state.message || "";
    var MarkdownIt = require("markdown-it");
    var emoji = require("markdown-it-emoji");
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(message));

    const { attachmentsInline } = this.state;
    for (let i in attachmentsInline) {
      var replace = 'src="' + attachmentsInline[i].id + '"';
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"');
    }

    let markdown = { __html: result };
    this.setState({ markdownNew: markdown });
  }

  private _handleTabSelectNew = (e: SelectTabEvent, data: any): void => {
    if (data.value === "preview" && this.state.tabSelected === "write") {
      this.getMarkdownNew();
    }
    this._isMounted &&
      this.setState({
        tabSelectedNew: data.value,
      });
  };

  public render() {
    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
      return personasToReturn;
    };

    const filterContactsByText = (
      filterText: string,
      currentPersonas: IPersonaProps[],
      limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
      if (filterText) {
        let filteredPersonas: IPersonaProps[] = this.state.possibleContacts.filter((item) =>
          doesTextStartWith(item, filterText)
        );
        filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
        filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
        return filterPromise(filteredPersonas);
      } else {
        return [];
      }
    };

    const menuPropsConversation: any = {
      items: [
        {
          key: "editConversation",
          text: i18n.t("app:editConversation"),
          iconProps: { iconName: "Edit" },
          onClick: () => {
            this.props.headerRef && this.props.headerRef._showConversationFormEdit();
          },
          hidden: this.props.userData && this.props.userData.user.id !== this.state.item.createdByUserId,
        },
      ],
      directionalHintFixed: true,
    };

    let companyUrl = this.props.userData?.repository.deploymentDomain.replaceAll(".com", "").replaceAll(".", "-");

    const menuPropsAttachment: any = (attachment, message?) => {
      return {
        items: [
          {
            key: "downloadAttachment",
            text: i18n.t("app:downloadAttachment"),
            iconProps: { iconName: "Download" },
            onClick: () => {
              this.downloadAttachment(message, attachment);
            },
          },
          {
            key: "openInDrive",
            text: i18n.t("app:openInDrive"),
            iconProps: { iconName: "OpenFile" },
            onClick: () => {
              window.open("https://synergyos.com/" + companyUrl + "/path/" + attachment.driveFileId, "_blank");
            },
            hidden: !message || !attachment.driveFileId,
          },
        ],
        directionalHintFixed: true,
      };
    };

    const menuPropsAttachmentEdit: any = (attachment, message?) => {
      return {
        items: [
          {
            key: "downloadAttachment",
            text: i18n.t("app:downloadAttachment"),
            iconProps: { iconName: "Download" },
            onClick: () => {
              this.downloadAttachment(message, attachment);
            },
          },
          {
            key: "openInDrive",
            text: i18n.t("app:openInDrive"),
            iconProps: { iconName: "OpenFile" },
            onClick: () => {
              window.open("https://synergyos.com/" + companyUrl + "/path/" + attachment.driveFileId, "_blank");
            },
            hidden: !message || !attachment.driveFileId,
          },
          {
            key: "delete",
            text: i18n.t("app:delete"),
            iconProps: { iconName: "Delete" },
            onClick: () => {
              let attachments = this.state.attachmentsOutline;
              attachments = attachments.filter((a) => {
                return a.id !== attachment.id;
              });
              this._isMounted && this.setState({ attachmentsOutline: attachments });
            },
          },
        ],
        directionalHintFixed: true,
      };
    };

    const renderItemWithSecondaryText: any = (props: IPeoplePickerItemSelectedProps) => {
      const newProps = {
        ...props,
        item: {
          ...props.item,
          ValidationState: ValidationState.valid,
          //showSecondaryText: true,
        },
      };

      return (
        <PeoplePickerItem
          {...newProps}
          className={"conversationDestinatary " + (props.item.id ? "internal" : "external")}
        />
      );
    };
    const menuProps: any = (item, i) => {
      return {
        items: [
          {
            key: "editMessage",
            text: i18n.t("app:editMessage"),
            iconProps: { iconName: "Edit" },
            onClick: () => {
              this._openEditMode(item.id);
            },
            hidden: this.props.userData && this.props.userData.user.id !== item.createdByUserId,
          },
          {
            key: "deleteMessage",
            text: i18n.t("app:deleteMessage"),
            iconProps: { iconName: "Delete" },
            onClick: () => {
              this.props.headerRef &&
                this.props.headerRef
                  .showConfirmDialog(i18n.t("app:deleteMessage"), i18n.t("app:areYouSureMessage"))
                  .then((result) => {
                    if (result) {
                      this._deleteMessage(item.id);
                    }
                  });
            },
            hidden: i === 0 || (this.props.userData && this.props.userData.user.id !== item.createdByUserId),
          },
        ],
        directionalHintFixed: true,
      };
    };

    var onDragEnter = (event) => {
      event.target.style.background = "#C7E0F4";
    };

    var onDragLeave = (event) => {
      event.target.style.background = "transparent";
    };

    var onDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    var saveAttachments = (files, id) => {
      for (let i in files) {
        if (files[i] && files[i].size >= 10000000) {
          this.props.headerRef.showAlertDialog("Error", "Maximum attachment allowed size: 10 Mb.");
        } else {
          const dropFormData = new FormData();
          const fileExtension = files[i].name.split(".").pop();
          const inlineTypes = ["jpg", "jpeg", "png", "svg", "jfif", "pjpeg", "pjp", "gif", "webp"];
          dropFormData.append("attachment", files[i], files[i].name);
          dropFormData.append("inline", inlineTypes.includes(fileExtension) ? "true" : "false");
          this._isMounted && this.setState({ savingAttachment: true });
          userService
            .saveAttachment(dropFormData)
            .then((response) => {
              if (inlineTypes.includes(fileExtension)) {
                let attachments = this.state.attachments;
                attachments.push(response.data.id);
                let attachmentsInline = this.state.attachmentsInline;
                attachmentsInline.push({ id: response.data.id, file: URL.createObjectURL(files[i]) });
                this._isMounted && this.setState({ attachments: attachments, attachmentsInline: attachmentsInline });
                const insertText = `![${response.data.name}](${response.data.id})`;
                id === "response-textbox"
                  ? this.insertAtCursor(document.getElementById(id), insertText)
                  : this.insertAtCursor(document.getElementById(id), insertText, true);
                this._isMounted && this.setState({ savingAttachment: false });
              } else {
                let attachments = this.state.attachments;
                attachments.push(response.data.id);
                let attachmentsOutline = this.state.attachmentsOutline;
                attachmentsOutline.push({ ...response.data, file: files[i] });
              }
              this._isMounted && this.setState({ savingAttachment: false });
            })
            .catch((error) => {
              console.log(error);
              this._isMounted && this.setState({ savingAttachment: false });
              if (error && error.data && error.data.message && this.props.headerRef) {
                this.props.headerRef.showAlertDialog("Error", error.data.message);
              } else {
                this.props.headerRef.showAlertDialog(
                  "Error",
                  "An error occurred trying to save the attachment. Please try again."
                );
              }
            });
        }
      }
    };

    var onFileDrop = (event, id) => {
      event.stopPropagation();
      event.preventDefault();

      event.target.style.background = "transparent";

      if (event.dataTransfer.files.length > 0) {
        const files: File[] = Array.from(event.dataTransfer.files);
        saveAttachments(files, id);
      }
    };

    var onPaste = (e, id) => {
      // Prevent the default behavior, so you can code your own logic.
      e.preventDefault();
      if (!e.clipboardData.files.length) {
        return;
      }
      const files: File[] = Array.from(e.clipboardData.files);
      saveAttachments(files, id);
    };

    return this.state.loading ? (
      <Spinner className="mt-4" />
    ) : (
      <div className="h-100 d-flex flex-column">
        <div style={{ background: "white", borderBottom: "1px solid #f2f2f2" }}>
          <div className="d-flex align-items-center px-2 py-2">
            <div
              className="d-flex align-items-center mr-3 ml-3"
              style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "wrap" }}
            >
              <span className="mr-2">Created by </span>
              <span className="mt-1">{this.getPersona(this.state.item.createdByUserId)}</span>
              <span>at {Moment(this.state.item.modifiedDateTime).format(i18n.t("Y-MMM-DD HH:mm"))}</span>
            </div>
            <IconButton
              className="ml-auto"
              iconProps={{ iconName: "More" }}
              menuProps={menuPropsConversation}
              menuIconProps={{ iconName: "", style: { display: "none" } }}
            />
          </div>
          <div>
            <div className="d-flex align-items-center ml-4 mb-3" style={{}}>
              <Icon
                iconName={
                  this.state.item.conversationType === "GENERAL"
                    ? "Chat"
                    : this.state.item.conversationType === "CONVERSATION"
                    ? "OfficeChat"
                    : this.state.item.conversationType === "ANNOUNCEMENT"
                    ? "Megaphone"
                    : this.state.item.conversationType === "APPROVAL"
                    ? "DocumentApproval"
                    : this.state.item.conversationType === "REVIEW"
                    ? "CheckboxComposite"
                    : this.state.item.conversationType === "MEETING"
                    ? "JoinOnlineMeeting"
                    : ""
                }
                style={{ fontSize: "14px", color: "rgb(16, 110, 190)" }}
              />
              <span className="ml-2 mr-3" style={{ fontSize: 14 }}>
                {this.state.item.conversationType === "GENERAL"
                  ? "General"
                  : this.state.item.conversationType === "CONVERSATION"
                  ? "Conversation"
                  : this.state.item.conversationType === "ANNOUNCEMENT"
                  ? "Announcement"
                  : this.state.item.conversationType === "APPROVAL"
                  ? "Approval"
                  : this.state.item.conversationType === "REVIEW"
                  ? "Review"
                  : this.state.item.conversationType === "MEETING"
                  ? "Meeting"
                  : ""}
              </span>
            </div>
          </div>
        </div>
        <div
          id="main-view"
          className="flex-grow-1"
          style={{ overflowY: "auto", backgroundColor: "rgb(250, 249, 248)", position: "relative" }}
        >
          <div id="main-view-conversation" className="d-flex flex-column h-100" style={{ overflowY: "auto" }}>
            <div>
              {this.state.item.messages &&
                this.state.item.messages.map((message, i) =>
                  i === 0 && this.state.item.conversationType === "APPROVAL" ? (
                    <div id={message.id} className="message-wrapper d-flex px-3">
                      <div className="vertical-hr"></div>
                      <Persona
                        className="mr-2"
                        style={{ marginTop: "3px" }}
                        name={this.getContactName(message.createdByUserId)}
                        size="small"
                        primaryText=""
                        avatar={{ color: "colorful", style: { margin: 0 } }}
                      />
                      <div
                        className="message flex-grow-1"
                        style={{
                          maxWidth: "calc(100% - 36px)",
                          border: this.props.target === message.id ? "1px solid rgb(16, 110, 190)" : "",
                        }}
                      >
                        <div
                          className="message-top d-flex w-100"
                          style={{
                            backgroundColor: this.props.userData.user.id === message.createdByUserId ? "#C7E0F4" : "",
                          }}
                        >
                          <div className="author d-flex align-items-center">
                            {this.getContactNameTooltip(message.createdByUserId)}
                            <span className="pl-1">
                              on {Moment(message.modifiedDateTime).format(i18n.t("Y-MMM-DD HH:mm"))}
                            </span>
                            {message.modifiedDateTime !== message.createdDateTime && (
                              <span className="ml-1">(Edited)</span>
                            )}
                          </div>
                          <IconButton
                            className="ml-auto"
                            styles={{ root: { width: "20px", height: "20px" } }}
                            iconProps={{ iconName: "More" }}
                            menuProps={menuProps(message, i)}
                            menuIconProps={{ iconName: "", style: { display: "none" } }}
                          />
                        </div>
                        <div className="message-content">
                          {message.to && message.to.length > 0 ? (
                            <div className="to d-flex align-items-center">
                              <span className="mr-2">To:</span>{" "}
                              {message.item.to.map((data) => {
                                return this.getUserFromEmail(data);
                              })}
                            </div>
                          ) : null}
                          {message.cc && message.cc.length > 0 ? (
                            <div className="cc d-flex align-items-center">
                              <span className="mr-2">Cc:</span>{" "}
                              {message.item.cc.map((data) => {
                                return this.getUserFromEmail(data);
                              })}
                            </div>
                          ) : null}
                          <p>{message.content || message.message}</p>
                        </div>
                        <div className="message-content pt-2" style={{ borderTop: "1px solid #f2f2f2" }}>
                          <ChoiceGroup
                            className="color-choicegroup"
                            styles={{ flexContainer: { display: "flex", gap: "1rem" } }}
                            label="Approval"
                            defaultSelectedKey={"requestChanges"}
                            options={[
                              {
                                key: "requestChanges",
                                text: "Request changes",
                                onRenderLabel: (item: any) => {
                                  return (
                                    <div className="ml-4 d-flex align-items-center">
                                      <div
                                        className="mx-1"
                                        style={{
                                          marginTop: "2px",
                                          height: "10px",
                                          width: "10px",
                                          backgroundColor: "#d69ca5",
                                          borderRadius: "10px",
                                        }}
                                      />
                                      {item.text}
                                    </div>
                                  );
                                },
                              },
                              {
                                key: "message",
                                text: "Message",
                                onRenderLabel: (item: any) => {
                                  return (
                                    <div className="ml-4 d-flex align-items-center">
                                      <div
                                        className="mx-1"
                                        style={{
                                          marginTop: "2px",
                                          height: "10px",
                                          width: "10px",
                                          backgroundColor: "#f9e2ae",
                                          borderRadius: "10px",
                                        }}
                                      />
                                      {item.text}
                                    </div>
                                  );
                                },
                              },
                              {
                                key: "approve",
                                text: "Approve",
                                onRenderLabel: (item: any) => {
                                  return (
                                    <div className="ml-4 d-flex align-items-center">
                                      <div
                                        className="mx-1"
                                        style={{
                                          marginTop: "2px",
                                          height: "10px",
                                          width: "10px",
                                          backgroundColor: "#bdd99b",
                                          borderRadius: "10px",
                                        }}
                                      />
                                      {item.text}
                                    </div>
                                  );
                                },
                              },
                            ]}
                          />
                          <div className="d-flex align-items-center w-100 mt-2">
                            <Textarea
                              className="flex-grow w-100"
                              placeholder="Write your reply"
                              value=""
                              style={{ height: "100px" }}
                              textarea={{ style: { height: "100%" } }}
                            />
                          </div>
                          <div className="d-flex mt-2">
                            <PrimaryButton className="ml-auto" text="Submit" />
                          </div>
                        </div>
                        <div className="message-bottom d-flex w-100">
                          <div className="ml-auto">
                            <IconButton
                              iconProps={{ iconName: "IconSetsFlag" }}
                              styles={{ icon: { color: "rgb(164, 38, 44)" } }}
                              onClick={() => this.props.headerRef && this.props.headerRef._showTaskForm(message.id)}
                            />
                            <IconButton
                              iconProps={{ iconName: "Emoji2" }}
                              styles={{ icon: { color: "rgb(97, 97, 97)" } }}
                            />
                            <IconButton
                              iconProps={{ iconName: "Reply" }}
                              styles={{ icon: { color: "#6C6DBE" } }}
                              onClick={() => {
                                //this.setState({replyTo: POST_AUTHOR})
                              }}
                            />
                            <IconButton
                              iconProps={{ iconName: "ReplyAll" }}
                              styles={{ icon: { color: "#6C6DBE" } }}
                              onClick={() => {
                                //this.setState({
                                //replyToAll: ALL_TO_DESTINATARIES
                                //replyCcAll: ALL_CC_DESTINATARIES
                                //})
                              }}
                            />
                            <IconButton
                              iconProps={{ iconName: "Forward" }}
                              styles={{ root: { borderBottomRightRadius: "6px" } }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={message.id} id={message.id} className="message-wrapper d-flex px-3">
                      <div className="vertical-hr"></div>
                      <Persona
                        className="mr-2"
                        style={{ marginTop: "3px" }}
                        name={this.getContactName(message.createdByUserId)}
                        size="small"
                        primaryText=""
                        avatar={{ color: "colorful", style: { margin: 0 } }}
                      />
                      <div
                        className="message flex-grow-1"
                        style={{
                          maxWidth: "calc(100% - 36px)",
                          border: this.props.target === message.id ? "1px solid rgb(16, 110, 190)" : "",
                        }}
                      >
                        <div
                          className="message-top d-flex w-100"
                          style={{
                            backgroundColor: this.props.userData.user.id === message.createdByUserId ? "#C7E0F4" : "",
                          }}
                        >
                          <div className="author d-flex align-items-center">
                            {this.getContactNameTooltip(message.createdByUserId)}
                            <span className="pl-1">
                              on {Moment(message.createdDateTime).format(i18n.t("Y-MMM-DD HH:mm"))}
                            </span>
                            {message.modifiedDateTime !== message.createdDateTime && (
                              <span className="ml-1">(Edited)</span>
                            )}
                          </div>
                          <IconButton
                            className="ml-auto"
                            styles={{ root: { width: "20px", height: "20px" } }}
                            iconProps={{ iconName: "More" }}
                            menuProps={menuProps(message, i)}
                            menuIconProps={{ iconName: "", style: { display: "none" } }}
                          />
                        </div>
                        <div className="message-content">
                          {message.to && message.to.length > 0 ? (
                            <div className="to d-flex align-items-center">
                              <span className="mr-2">To:</span>{" "}
                              {message.to.map((data) => {
                                return this.getUserFromEmail(data);
                              })}
                            </div>
                          ) : null}
                          {message.cc && message.cc.length > 0 ? (
                            <div className="cc d-flex align-items-center">
                              <span className="mr-2">Cc:</span>{" "}
                              {message.cc.map((data) => {
                                return this.getUserFromEmail(data);
                              })}
                            </div>
                          ) : null}
                          {this.state.editingMessage === message.id ? (
                            <div style={{ marginTop: "12px" }}>
                              <div className="d-flex align-items-center">
                                <TabList
                                  className="mb-2"
                                  selectedValue={this.state.tabSelected}
                                  onTabSelect={(e, data) => {
                                    this._handleTabSelect(e, data, message.id);
                                  }}
                                >
                                  <Tab value={"write"}>{i18n.t("app:write")}</Tab>
                                  <Tab value={"preview"}>{i18n.t("app:preview")}</Tab>
                                </TabList>
                                <IconButton
                                  onClick={this._showFilePicker.bind(this)}
                                  className="ml-auto normal-button-opacity"
                                  iconProps={{ iconName: "Attach" }}
                                  styles={{ root: { borderBottomRightRadius: "6px" } }}
                                />
                              </div>
                              {this.state.attachmentsOutline.length > 0 && (
                                <div className="d-flex w-100" style={{ overflowX: "auto" }}>
                                  {this.state.attachmentsOutline.map((item) => {
                                    return (
                                      <div
                                        key={item.id}
                                        className="email-attachment pr-0"
                                        style={{ borderRadius: "4px" }}
                                      >
                                        <Icon
                                          className="mr-2"
                                          {...getFileTypeIconProps({
                                            extension: item.name.split(".")[item.name.split(".").length - 1],
                                            size: 24,
                                            imageFileType: "svg",
                                          })}
                                          style={{ minWidth: "24px", minHeight: "24px", width: "24px", height: "24px" }}
                                        />
                                        <span title={item.name}>{item.name}</span>
                                        {item.driveFileId && (
                                          <TooltipHost className="d-flex" content={i18n.t("app:savedInDrive")}>
                                            <div className="h-100 w-100 d-flex">
                                              <Icon
                                                className="m-auto px-1"
                                                iconName="FabricFolder"
                                                style={{ fontSize: 18 }}
                                              />
                                            </div>
                                          </TooltipHost>
                                        )}
                                        <IconButton
                                          style={{ height: "29px", width: "29px" }}
                                          menuProps={menuPropsAttachmentEdit(item, message)}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              <div
                                className="flex-grow-1 markdown-frame"
                                style={{
                                  minHeight: "109px",
                                  maxHeight: "270px",
                                  overflowY: "auto",
                                  display: this.state.tabSelected === "preview" ? "block" : "none",
                                  backgroundColor: "white",
                                  borderRadius: "4px",
                                  border: "1px solid #d1d1d1",
                                  padding: "6px 12px",
                                }}
                              >
                                <div className="markdown-body" dangerouslySetInnerHTML={this.state.markdown}></div>
                              </div>
                              <Textarea
                                id={"response-textbox-" + message.id}
                                onDragEnter={onDragEnter}
                                onDragLeave={onDragLeave}
                                onDragOver={onDragOver}
                                onDrop={(e) => onFileDrop(e, "response-textbox-" + message.id)}
                                onPaste={(e) => onPaste(e, "response-textbox-" + message.id)}
                                textarea={{ style: { minHeight: "100px", width: "100%" } }}
                                resize="none"
                                className="w-100"
                                value={this.state.editMessage}
                                onChange={(e, data) => {
                                  this._isMounted && this.setState({ editMessage: data.value });
                                }}
                                style={{
                                  display: this.state.tabSelected === "preview" ? "none" : "block",
                                  borderColor: "rgb(230,230,230)",
                                }}
                              />
                              {this.state.savingAttachment && (
                                <p className="m-0">
                                  <span className="mr-1" style={{ color: "rgb(66, 66, 66)" }}>
                                    {i18n.t("app:uploadingAttachment")}...
                                  </span>
                                </p>
                              )}
                              <div className="d-flex align-items-center w-100" style={{ marginTop: "12px" }}>
                                <p className="m-0">
                                  <span className="mr-1" style={{ color: "rgb(66, 66, 66)" }}>
                                    {i18n.t("app:supports")}
                                  </span>
                                  <Link target="_blank" href="https://www.markdownguide.org/basic-syntax/#overview">
                                    Markdown
                                  </Link>
                                  .
                                </p>
                                <DefaultButton
                                  className="ml-auto"
                                  text={i18n.t("app:cancel")}
                                  onClick={() => {
                                    this._closeEditMode(message.id);
                                  }}
                                />
                                <PrimaryButton
                                  style={{ marginLeft: "12px" }}
                                  text={i18n.t("app:save")}
                                  onClick={() => {
                                    this._updateMessage(message, message.id);
                                  }}
                                  disabled={!this.state.editMessage}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              {message.attachments &&
                                message.attachments.filter((a) => {
                                  return !a.inline;
                                }) &&
                                message.attachments.filter((a) => {
                                  return !a.inline;
                                }).length > 0 && (
                                  <div className="d-flex w-100" style={{ overflowX: "auto" }}>
                                    {message.attachments
                                      .filter((a) => {
                                        return !a.inline;
                                      })
                                      .map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className="email-attachment pr-0"
                                            style={{ borderRadius: "4px" }}
                                          >
                                            <Icon
                                              className="mr-2"
                                              {...getFileTypeIconProps({
                                                extension: item.name.split(".")[item.name.split(".").length - 1],
                                                size: 24,
                                                imageFileType: "svg",
                                              })}
                                              style={{
                                                minWidth: "24px",
                                                minHeight: "24px",
                                                width: "24px",
                                                height: "24px",
                                              }}
                                            />
                                            <span title={item.name}>{item.name}</span>
                                            {item.driveFileId && (
                                              <TooltipHost className="d-flex" content={i18n.t("app:savedInDrive")}>
                                                <div className="h-100 w-100 d-flex">
                                                  <Icon
                                                    className="m-auto px-1"
                                                    iconName="FabricFolder"
                                                    style={{ fontSize: 18 }}
                                                  />
                                                </div>
                                              </TooltipHost>
                                            )}
                                            <IconButton
                                              style={{ height: "29px", width: "29px" }}
                                              menuProps={menuPropsAttachment(item, message)}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                              <div className="markdown-frame p-0 pt-2">
                                <div
                                  className="markdown-body w-100"
                                  dangerouslySetInnerHTML={this.convertToMarkdown(
                                    message,
                                    message.content || message.message
                                  )}
                                ></div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="message-bottom d-flex w-100">
                          <div className="ml-auto">
                            <IconButton
                              iconProps={{ iconName: "IconSetsFlag" }}
                              styles={{ icon: { color: "rgb(164, 38, 44)" } }}
                              onClick={() =>
                                this.props.headerRef && this.props.headerRef._showTaskForm(this.state.item.id)
                              }
                            />
                            <IconButton
                              iconProps={{ iconName: "Emoji2" }}
                              styles={{ icon: { color: "rgb(97, 97, 97)" } }}
                            />
                            <IconButton
                              iconProps={{ iconName: "Reply" }}
                              styles={{ icon: { color: "#6C6DBE" } }}
                              onClick={() => {
                                //this.setState({replyTo: POST_AUTHOR})
                              }}
                            />
                            <IconButton
                              iconProps={{ iconName: "ReplyAll" }}
                              styles={{ icon: { color: "#6C6DBE" } }}
                              onClick={() => {
                                //this.setState({
                                //replyToAll: ALL_TO_DESTINATARIES
                                //replyCcAll: ALL_CC_DESTINATARIES
                                //})
                              }}
                            />
                            <IconButton
                              iconProps={{ iconName: "Forward" }}
                              styles={{ root: { borderBottomRightRadius: "6px" } }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            {this.state.page < this.state.totalPages - 1 && <Spinner className="mb-3" />}
            {this.state.item.messages && this.state.item.messages.length > 0 && (
              <hr style={{ borderColor: "#bfbfbf", margin: 0 }} />
            )}
            {this.props.userData && (
              <div className="message-wrapper d-flex px-3 mt-auto">
                <Persona
                  className="mr-2"
                  style={{ marginTop: "3px" }}
                  name={(this.props.userData.user.name || "") + " " + (this.props.userData.user.surname || "")}
                  size="small"
                  primaryText=""
                  avatar={{ color: "colorful", style: { margin: 0 } }}
                />
                {!this.state.showReply ? (
                  <Input
                    className="flex-grow-1"
                    placeholder={i18n.t("app:writeReply") + "..."}
                    onClick={() => {
                      this._isMounted &&
                        this.setState({
                          showReply: true,
                          attachments: [],
                          attachmentsInline: [],
                          attachmentsOutline: [],
                          editingMessage: "",
                        });
                    }}
                    style={{ marginTop: "2px" }}
                  />
                ) : (
                  <div className="message flex-grow-1" style={{ maxWidth: "calc(100% - 36px)" }}>
                    <div
                      className="message-top d-flex py-0 align-items-center"
                      style={{ backgroundColor: "#C7E0F4", height: "33px" }}
                    >
                      <span className="author ml-1">{i18n.t("app:reply")}</span>
                      <ActionButton
                        onClick={() => {
                          this._isMounted && this.setState({ showCC: true });
                        }}
                        className="ml-auto normal-button-opacity"
                        style={{
                          height: "27px",
                          display: this.state.showCC ? "none" : "block",
                          color: "rgb(16, 110, 190)",
                        }}
                        text={i18n.t("app:cc")}
                      />
                      <ActionButton
                        onClick={() => {
                          this._isMounted && this.setState({ showBCC: true });
                        }}
                        className={this.state.showCC ? "ml-auto normal-button-opacity" : "ml-2 normal-button-opacity"}
                        style={{
                          height: "27px",
                          display: this.state.showBCC ? "none" : "block",
                          color: "rgb(16, 110, 190)",
                        }}
                        text={i18n.t("app:bcc")}
                      />
                    </div>
                    <div className="message-content">
                      <div className="d-flex align-items-top w-100 mt-2">
                        <div>
                          <Label
                            className="px-4 text-center"
                            style={{ width: "80px", border: "1px solid rgb(230, 230, 230)", borderRadius: "4px" }}
                          >
                            {i18n.t("app:to")}
                          </Label>
                        </div>
                        <div className="flex-grow-1 xxx">
                          <NormalPeoplePicker
                            disabled={this.state.loadingEntities}
                            styles={{
                              text: {
                                border: "none",
                                borderBottom: "1px solid rgb(230, 230, 230) !important",
                                borderRadius: "none",
                              },
                              root: { padding: "10px 0" },
                            }}
                            onRenderSuggestionsItem={(item: any) => {
                              return (
                                <FluentProvider
                                  className="d-flex align-items-center w-100 px-2"
                                  style={{ background: "transparent" }}
                                >
                                  <Persona
                                    name={(item.name || "") + " " + (item.surname || "")}
                                    size="small"
                                    primaryText=""
                                    avatar={{ color: "colorful" }}
                                  />
                                  <div>
                                    <p
                                      className="p-0 m-0 text-left"
                                      style={{ fontSize: 14, fontWeight: 400, color: "rgb(50, 49, 48)" }}
                                    >
                                      {item.text}
                                    </p>
                                    <p className="p-0 m-0 text-left" style={{ color: "rgb(96, 94, 92)", fontSize: 12 }}>
                                      <Link target="_blank" href={"mailto:" + item.email}>
                                        {item.email}
                                      </Link>
                                    </p>
                                  </div>
                                </FluentProvider>
                              );
                            }}
                            //onItemSelected={(item)=>{return this.addContact(item)}}
                            onChange={(items) => {
                              this._isMounted && this.setState({ currentContacts: items || [] });
                            }}
                            onResolveSuggestions={(f, i: any) => filterContactsByText(f, i)}
                            onValidateInput={this.validateInput}
                            className="ml-2 p-0"
                            selectedItems={this.state.currentContacts}
                            onRenderItem={renderItemWithSecondaryText}
                          />
                        </div>
                      </div>
                      <div
                        className="align-items-top w-100 mt-2"
                        style={{ display: this.state.showCC ? "flex" : "none" }}
                      >
                        <div>
                          <Label
                            className="px-4 text-center"
                            style={{ width: "80px", border: "1px solid rgb(230, 230, 230)", borderRadius: "4px" }}
                          >
                            {i18n.t("app:cc")}
                          </Label>
                        </div>
                        <div className="flex-grow-1 xxx">
                          <NormalPeoplePicker
                            disabled={this.state.loadingEntities}
                            styles={{
                              text: {
                                border: "none",
                                borderBottom: "1px solid rgb(230, 230, 230) !important",
                                borderRadius: "none",
                              },
                              root: { padding: "10px 0" },
                            }}
                            onRenderSuggestionsItem={(item: any) => {
                              return (
                                <FluentProvider
                                  className="d-flex align-items-center w-100 px-2"
                                  style={{ background: "transparent" }}
                                >
                                  <Persona
                                    name={(item.name || "") + " " + (item.surname || "")}
                                    size="small"
                                    primaryText=""
                                    avatar={{ color: "colorful" }}
                                  />
                                  <div>
                                    <p
                                      className="p-0 m-0 text-left"
                                      style={{ fontSize: 14, fontWeight: 400, color: "rgb(50, 49, 48)" }}
                                    >
                                      {item.text}
                                    </p>
                                    <p className="p-0 m-0 text-left" style={{ color: "rgb(96, 94, 92)", fontSize: 12 }}>
                                      <Link target="_blank" href={"mailto:" + item.email}>
                                        {item.email}
                                      </Link>
                                    </p>
                                  </div>
                                </FluentProvider>
                              );
                            }}
                            //onItemSelected={(item)=>{return this.addContactCopy(item)}}
                            onChange={(items) => {
                              this._isMounted && this.setState({ contactsCC: items || [] });
                            }}
                            onResolveSuggestions={(f, i: any) => filterContactsByText(f, i)}
                            onValidateInput={this.validateInput}
                            className="ml-2 p-0"
                            selectedItems={this.state.contactsCC}
                            onRenderItem={renderItemWithSecondaryText}
                          />
                        </div>
                      </div>
                      <div
                        className="align-items-top w-100 mt-2"
                        style={{ display: this.state.showBCC ? "flex" : "none" }}
                      >
                        <div>
                          <Label
                            className="px-4 text-center"
                            style={{ width: "80px", border: "1px solid rgb(230, 230, 230)", borderRadius: "4px" }}
                          >
                            {i18n.t("app:bcc")}
                          </Label>
                        </div>
                        <div className="flex-grow-1 xxx">
                          <NormalPeoplePicker
                            disabled={this.state.loadingEntities}
                            styles={{
                              text: {
                                border: "none",
                                borderBottom: "1px solid rgb(230, 230, 230) !important",
                                borderRadius: "none",
                              },
                              root: { padding: "10px 0" },
                            }}
                            onRenderSuggestionsItem={(item: any) => {
                              return (
                                <FluentProvider
                                  className="d-flex align-items-center w-100 px-2"
                                  style={{ background: "transparent" }}
                                >
                                  <Persona
                                    name={(item.name || "") + " " + (item.surname || "")}
                                    size="small"
                                    primaryText=""
                                    avatar={{ color: "colorful" }}
                                  />
                                  <div>
                                    <p
                                      className="p-0 m-0 text-left"
                                      style={{ fontSize: 14, fontWeight: 400, color: "rgb(50, 49, 48)" }}
                                    >
                                      {item.text}
                                    </p>
                                    <p className="p-0 m-0 text-left" style={{ color: "rgb(96, 94, 92)", fontSize: 12 }}>
                                      <Link target="_blank" href={"mailto:" + item.email}>
                                        {item.email}
                                      </Link>
                                    </p>
                                  </div>
                                </FluentProvider>
                              );
                            }}
                            //onItemSelected={(item)=>{return this.addContactCopy(item)}}
                            onChange={(items) => {
                              this._isMounted && this.setState({ contactsBCC: items || [] });
                            }}
                            onResolveSuggestions={(f, i: any) => filterContactsByText(f, i)}
                            onValidateInput={this.validateInput}
                            className="ml-2 p-0"
                            selectedItems={this.state.contactsBCC}
                            onRenderItem={renderItemWithSecondaryText}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <TabList
                          className="mb-2 mt-1"
                          selectedValue={this.state.tabSelectedNew}
                          onTabSelect={this._handleTabSelectNew}
                        >
                          <Tab value={"write"}>{i18n.t("app:write")}</Tab>
                          <Tab value={"preview"}>{i18n.t("app:preview")}</Tab>
                        </TabList>
                        <IconButton
                          onClick={this._showFilePicker.bind(this)}
                          className="ml-auto normal-button-opacity"
                          iconProps={{ iconName: "Attach" }}
                          styles={{ root: { borderBottomRightRadius: "6px" } }}
                        />
                      </div>
                      {this.state.attachmentsOutline.length > 0 && (
                        <div className="d-flex w-100" style={{ overflowX: "auto" }}>
                          {this.state.attachmentsOutline.map((item) => {
                            return (
                              <div key={item.id} className="email-attachment pr-0" style={{ borderRadius: "4px" }}>
                                <Icon
                                  className="mr-2"
                                  {...getFileTypeIconProps({
                                    extension: item.name.split(".")[item.name.split(".").length - 1],
                                    size: 24,
                                    imageFileType: "svg",
                                  })}
                                  style={{ minWidth: "24px", minHeight: "24px", width: "24px", height: "24px" }}
                                />
                                <span title={item.name}>{item.name}</span>
                                {item.driveFileId && (
                                  <TooltipHost className="d-flex" content={i18n.t("app:savedInDrive")}>
                                    <div className="h-100 w-100 d-flex">
                                      <Icon className="m-auto px-1" iconName="FabricFolder" style={{ fontSize: 18 }} />
                                    </div>
                                  </TooltipHost>
                                )}
                                <IconButton
                                  style={{ height: "29px", width: "29px" }}
                                  menuProps={menuPropsAttachmentEdit(item)}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div className="d-flex align-items-center w-100 mt-2">
                        <div
                          className="flex-grow-1 markdown-frame"
                          style={{
                            minHeight: "104px",
                            maxHeight: "270px",
                            overflowY: "auto",
                            display: this.state.tabSelectedNew === "preview" ? "block" : "none",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            border: "1px solid #d1d1d1",
                            padding: "6px 12px",
                          }}
                        >
                          <div className="markdown-body" dangerouslySetInnerHTML={this.state.markdownNew}></div>
                        </div>
                        <div
                          className="flex-grow-1"
                          style={{ display: this.state.tabSelectedNew === "preview" ? "none" : "flex" }}
                        >
                          <Textarea
                            id="response-textbox"
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDragOver={onDragOver}
                            onDrop={(e) => onFileDrop(e, "response-textbox")}
                            onPaste={(e) => onPaste(e, "response-textbox")}
                            className="flex-grow-1 w-100"
                            placeholder={i18n.t("app:writeReply") + "..."}
                            value={this.state.message}
                            textarea={{ style: { minHeight: "100px" } }}
                            onChange={(e, data) => {
                              this.setState({ message: data.value });
                            }}
                            resize={"none"}
                            style={{ borderColor: "rgb(230,230,230)" }}
                            autoFocus
                          />
                        </div>
                      </div>
                      {this.state.savingAttachment && (
                        <p className="m-0">
                          <span className="mr-1" style={{ color: "rgb(66, 66, 66)" }}>
                            {i18n.t("app:uploadingAttachment")}...
                          </span>
                        </p>
                      )}
                      <div className="d-flex align-items-center w-100 mt-2">
                        <p className="m-0">
                          <span className="mr-1" style={{ color: "rgb(66, 66, 66)" }}>
                            {i18n.t("app:supports")}
                          </span>
                          <Link target="_blank" href="https://www.markdownguide.org/basic-syntax/#overview">
                            Markdown
                          </Link>
                          .
                        </p>
                        <DefaultButton
                          className="ml-auto cancel-button"
                          text={i18n.t("app:cancel")}
                          onClick={() => {
                            this._isMounted &&
                              this.setState({
                                showReply: false,
                                attachments: [],
                                attachmentsInline: [],
                                attachmentsOutline: [],
                                editingMessage: "",
                              });
                          }}
                        />
                        <PrimaryButton
                          className="ml-3"
                          text={i18n.t("app:send")}
                          iconProps={{ iconName: "Send" }}
                          onClick={() => {
                            this._reply();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf("@") !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private getUserFromEmail(email: string) {
    const { possibleContacts } = this.state;
    let user = possibleContacts.find((user) => {
      return user.email === email;
    });
    return user ? (
      <Persona
        key={email}
        name={(user.name || "") + " " + (user.surname || "")}
        size="extra-small"
        avatar={{ color: "colorful" }}
      />
    ) : (
      <Persona className="mr-2" key={email} name={email} size="extra-small" avatar={{ color: "colorful" }} />
    );
  }

  private insertAtCursor(myField, myValue, messageEdit = false) {
    if (myField.selectionStart || myField.selectionStart == "0") {
      var startPos = myField.selectionStart;
      var endPos = myField.selectionEnd;
      if (messageEdit) {
        this._isMounted &&
          this.setState(
            {
              editMessage:
                myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length),
            },
            () => {
              myField.selectionStart = startPos + myValue.length;
              myField.selectionEnd = startPos + myValue.length;
            }
          );
      } else {
        this._isMounted &&
          this.setState(
            {
              message:
                myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length),
            },
            () => {
              myField.selectionStart = startPos + myValue.length;
              myField.selectionEnd = startPos + myValue.length;
            }
          );
      }
    } else {
      if (messageEdit) {
        this._isMounted && this.setState({ editMessage: myField.value + myValue });
      } else {
        this._isMounted && this.setState({ message: myField.value + myValue });
      }
    }
  }

  private _showFilePicker() {
    var input = document.createElement("input");
    input.type = "file";
    input.multiple = true;

    input.onchange = (e) => {
      let event: any = e as Event;
      event.stopPropagation();
      event.preventDefault();
      const files: File[] = Array.from(event.target.files);
      if (files.length > 0) {
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            this.props.headerRef.showAlertDialog("Error", "Maximum attachment allowed size: 25 Mb.");
          } else {
            const dropFormData = new FormData();
            dropFormData.append("attachment", files[i], files[i].name);
            dropFormData.append("inline", "false");
            this._isMounted && this.setState({ savingAttachment: true });
            userService
              .saveAttachment(dropFormData)
              .then((response) => {
                let attachments = this.state.attachments;
                attachments.push(response.data.id);
                let attachmentsOutline = this.state.attachmentsOutline;
                attachmentsOutline.push({ ...response.data, file: files[i] });
                this._isMounted &&
                  this.setState({
                    savingAttachment: false,
                    attachments: attachments,
                    attachmentsOutline: attachmentsOutline,
                  });
              })
              .catch((error) => {
                console.log(error);
                this._isMounted && this.setState({ savingAttachment: false });
                if (error && error.data && error.data.message && this.props.headerRef) {
                  this.props.headerRef.showAlertDialog("Error", error.data.message);
                } else {
                  this.props.headerRef.showAlertDialog(
                    "Error",
                    "An error occurred trying to save the attachment. Please try again."
                  );
                }
              });
          }
        }
      }
    };

    input.click();
  }

  private downloadAttachment(message, item) {
    userService.downloadConversationAttachment(this.state.item.id, message.id, item.id, true).then((response) => {
      var url = window.URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = item.name;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);
    });
  }
}

function doesTextStartWith(item: any, filterText: string): boolean {
  let email = item.email;
  return (
    item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
    (email && email.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
  );
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}
