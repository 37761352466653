import * as React from "react";

import { Link } from "@fluentui/react/lib/Link";
import i18n from "i18next";

export interface Props {}

export interface SidebarState {}

export class ActivityDetails extends React.Component<Props, SidebarState> {
  props: any;

  public render() {
    const { item } = this.props;

    var model_objects: any = item.model_objects;
    var old_2nd_model_objects: any = item.old_2nd_model_objects;

    var object = model_objects && model_objects[0];
    var secondaryObject = old_2nd_model_objects && old_2nd_model_objects[0];

    var activityDetails: string = "";

    var driveFiles = "";
    if (this.props.userData) {
      let companyUrl = this.props.userData?.repository.deploymentDomain.replaceAll(".com", "").replaceAll(".", "-");
      driveFiles = "https://synergyos.com/" + companyUrl;
    }

    if (item.operationType === "SEARCH") {
      activityDetails = (item.message || "").replace(/[{}]/g, "");
    } else if (
      (item.operationType === "DEMOTE" || item.operationType === "PROMOTE") &&
      item.destination &&
      item.destination.modelType === "REVISION"
    ) {
      let version = "";
      if ((item.destination.version || "").includes("-draft")) {
        version = i18n.t("activity:draftPost") + " v" + item.destination.version.replace("-draft", "");
      } else if ((item.destination.version || "").includes("Draft post-")) {
        version = i18n.t("activity:draftPost") + " v" + item.destination.version.replace("Draft post-", "");
      } else version = "v" + item.destination.version;
      activityDetails = version + (item.message ? ' "' + item.message + '"' : "");
    } else if (item.targets && item.targets[0] && item.targets[0].modelType === "REVISION") {
      let version = "";
      if ((item.targets[0].version || "").includes("-draft")) {
        version = i18n.t("activity:draftPost") + " v" + item.targets[0].version.replace("-draft", "");
      } else if ((item.targets[0].version || "").includes("Draft post-")) {
        version = i18n.t("activity:draftPost") + " v" + item.targets[0].version.replace("Draft post-", "");
      } else version = "v" + item.targets[0].version;
      activityDetails = version + (item.message ? ' "' + item.message + '"' : "");
    } else if (item.targets && item.targets.length === 1 && item.targets[0] && item.targets[0].modelType === "FILE") {
      let version = "";
      if (item.targets && item.targets[0] && (item.targets[0].version || "").includes("-draft")) {
        version = i18n.t("activity:draftPost") + " v" + item.targets[0].version.replace("-draft", "");
      } else if (item.targets && item.targets[0] && (item.targets[0].version || "").includes("Draft post-")) {
        version = i18n.t("activity:draftPost") + " v" + item.targets[0].version.replace("Draft post-", "");
      } else if (item.targets && item.targets[0]) {
        version = "v" + item.targets[0].version;
      }
      let version2 = "";
      if (item.source && (item.source.version || "").includes("-draft")) {
        version2 = i18n.t("activity:draftPost") + " v" + item.source.version.replace("-draft", "");
      } else if (item.source && (item.source.version || "").includes("vDraft post-")) {
        version2 = i18n.t("activity:draftPost") + " v" + item.source.version.replace("vDraft post-", "");
      } else if (item.source && item.source.version) {
        version2 = "v" + item.source.version;
      }
      activityDetails = version || version2;
    } else if (
      item.operationType.includes("SHARE") &&
      item.targets &&
      item.targets[0] &&
      item.targets[0].modelType === "GROUP"
    ) {
      activityDetails = "groups";
      return item.targets.map((target, i) => {
        if (this.props.repoUsers.groups) {
          let group = this.props.repoUsers.groups.find((g) => {
            return g.id === target.id;
          });
          if (group)
            return (
              <span className="mr-2" key={target.id}>
                {group.name}
              </span>
            );
        }
        return <span></span>;
      });
    } else if (item.targets && item.targets[0] && item.targets[0].modelType === "SMART_FOLDER") {
      activityDetails = item.targets[0].name;
    } else if (item.targets && item.targets[0] && item.targets[0].modelType === "COMMENT") {
      activityDetails = item.message;
    } else if (item.targets && item.targets[0] && item.targets[0].modelType === "TAG") {
      activityDetails = item.message;
    } else if (item.users && item.users.length > 0) {
      return item.users.map((target) => {
        if (this.props.repoUsers) {
          let user = this.props.repoUsers.find((g) => {
            return g.id === target;
          });
          if (user)
            return (
              <span className="mr-2" key={target}>
                {(user.name || "") + " " + (user.surname || "")}
              </span>
            );
        }
        return <span></span>;
      });
    }

    if (item.operationType === "DUPLICATE") {
      if (item.source && item.source.modelType === "FOLDER") {
        return <div></div>;
      } else if (item.source && item.source.modelType === "FILE") {
        return (
          <div>
            <Link href={driveFiles + "/path/" + item.source.id}>{item.source.name}</Link>
          </div>
        );
      }
    }

    if (item.operationType === "MOVE") {
      if (item.destination && item.source) {
        return (
          <div>
            {i18n.t("activity:from")}
            <Link className="mx-1" href={"/drive/" + item.destination.id}>
              {item.destination.name}
            </Link>
            {i18n.t("activity:to")}
            <Link className="mx-1" href={"/drive/" + item.source.id}>
              {item.source.name}
            </Link>
          </div>
        );
      }
      activityDetails = item.message;
    }

    if (item.operationType === "RENAME") {
      activityDetails = i18n.t("activity:previousName") + ": " + item.source.name;
    }

    if (item.operationType === "CREATE_SHARE_BY_LINK") {
      activityDetails = "";
    }

    if (item.type === "COMPANY") {
      if (item.operation === "ADD_INTERNAL" && object) {
        activityDetails = "email: " + object.email;
        return (
          <span title={activityDetails}>
            email:{" "}
            <a href={"mailto:" + object.email} target="_blank" rel="noreferrer">
              {object.email}
            </a>
          </span>
        );
      }
    }

    if (item.type === "GROUP") {
      if ((item.operation === "CREATE" || item.operation === "UPDATE") && secondaryObject) {
        activityDetails = i18n.t("activity:name") + ': "' + secondaryObject.name + '"';
      }
    }

    if (item.operation === "SEARCH" && item.message) {
      var message = item.message.substring(1, item.message.length - 1);
      message = message.split(", ");
      message = message.filter((term) => {
        return !term.startsWith("parentId");
      });
      message = message.join(", ");
      activityDetails = message;
    }

    if (item.operationType === "COMPARE") {
      var file1 = item.targets[0];
      var file2 = item.targets[1];
      if (file1 && file2 && file1.id === file2.id) {
        activityDetails = file1.name + " " + file1.version + " vs. " + file2.version;
        return (
          <span title={activityDetails}>
            "<Link href={driveFiles + "/path/" + file1.id}>{"FILE"}</Link>" v{file1.version} vs. v{file2.version}
          </span>
        );
      } else {
        return (
          <span title={activityDetails}>
            "<Link href={driveFiles + "/path/" + file1.id}>{file1.name + " " + file1.version}</Link>" vs. "
            <Link href={driveFiles + "/path/" + file2.id}>{file2.name + " " + file2.version}</Link>"
          </span>
        );
      }
    }

    return <span title={activityDetails}>{activityDetails}</span>;
  }
}
