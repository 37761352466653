import "moment/min/locales";

import * as React from "react";

import { Callout, DirectionalHint } from "@fluentui/react";
import {
  ConstrainMode,
  IColumn,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { Fabric, Link } from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react";

import { ActivityDetails } from "../_components/ActivityDetails";
import { DetailsPanel } from "../_components/DetailsPanel";
import { Header } from "../_components/Header";
import { Icon } from "@fluentui/react";
import Moment from "moment";
import { PrimaryButton } from "@fluentui/react";
import { Sidebar } from "../_components/Sidebar";
import i18n from "i18next";
import { userService } from "../_services";

export interface Props {
  location: any;
  userData: any;
}

export interface ActivityState {
  userData: any;
  breadcrumb: any[];
  columns: IColumn[];
  items: any;
  calloutTarget: any;
  startIndex: number;
  indexSize: number;
  loadingActivity: boolean;
  isLoadingMoreActivity: boolean;
  canLoadMore: boolean;
  searchQuery: any;
  selItems: any;
  showRightPanel: boolean;
  currentPage: number;
  repoUsers: any[];
}

export interface IDocument {
  id: string;
  date: string;
  user: string;
  action: string;
  targets: any;
  source: any;
  destination: any;
  type: string;
  operationType: string;
  message: string;
  model_objects: any;
  old_2nd_model_objects: any;
  searchQuery: string;
}

export class Activity extends React.Component<Props, ActivityState> {
  private _isMounted: boolean;
  private headerRef: any = React.createRef();
  private sidebarRef: any = React.createRef();
  private _selection: Selection;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1];

        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.setState({ selItems: selItem });
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.setState({ selItems: null });
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.setState({ selItems: null });
          }
        }, 50);
      },
    });

    const messages: any = i18n.t("activityMessages:messages", { returnObjects: true });
    let companyUrl = this.props.userData?.repository.deploymentDomain.replaceAll(".com", "").replaceAll(".", "-");
    const columns: IColumn[] = [
      {
        key: "date",
        name: i18n.t("activity:date"),
        fieldName: "dateValue",
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          return <span>{Moment(item.date).format("Y-MM-DD HH:mm")}</span>;
        },
        isPadded: true,
      },
      {
        key: "user",
        name: i18n.t("activity:user"),
        fieldName: "user",
        minWidth: 100,
        maxWidth: 160,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
            <div>
              <span title={item.user}>{item.user}</span>
            </div>
          );
        },
      },
      {
        key: "action",
        name: i18n.t("activity:activity"),
        fieldName: "action",
        minWidth: 120,
        maxWidth: 160,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
            <div>
              <span className="activity-description" title={item.action || i18n.t("app:unknown")}>
                {item.action || i18n.t("app:unknown")}
              </span>
            </div>
          );
        },
      },
      {
        key: "item",
        name: i18n.t("activity:item"),
        fieldName: "item",
        minWidth: 100,
        maxWidth: 160,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        columnActionsMode: 0,
        onRender: (item: IDocument) => {
          var itemText: string = "";
          if (item.type === "DOCUMENT" && item.operationType === "SEARCH") {
            itemText = "Files";
            return (
              <div>
                <span title={itemText}>{itemText}</span>
              </div>
            );
          } else if (
            item.operationType === "DUPLICATE" &&
            item.targets &&
            item.targets.length > 0 &&
            item.targets[0].modelType === "FILE"
          ) {
            return (
              <div>
                <Link
                  className="mr-2"
                  href={"https://synergyos.com/" + companyUrl + "/path/" + item.targets[0].id}
                  title={item.targets[0].name ?? "FILE"}
                >
                  {item.targets[0].name ?? "FILE"}
                </Link>
              </div>
            );
          } else if (item.operationType === "DUPLICATE" && item.destination && item.destination.modelType === "FILE") {
            return (
              <div>
                <Link
                  className="mr-2"
                  href={"https://synergyos.com/" + companyUrl + "/path/" + item.destination.id}
                  title={item.destination.name ?? "FILE"}
                >
                  {item.destination.name ?? "FILE"}
                </Link>
              </div>
            );
          } else if (item.operationType === "DUPLICATE" && item.source && item.source.modelType === "FOLDER") {
            return (
              <div>
                <Link
                  className="mr-2"
                  href={"https://synergyos.com/" + companyUrl + "/drive/" + item.source.id}
                  title={item.source.name ?? "FOLDER"}
                >
                  {item.source.name ?? "FOLDER"}
                </Link>
              </div>
            );
          } else if (
            item.operationType === "SEARCH" &&
            item.targets &&
            item.targets[0] &&
            (item.targets[0].modelType === "FILE" || item.targets[0].modelType === "FOLDER")
          ) {
            return (
              <div>
                <span>
                  {item.targets[0].modelType === "FOLDER" ? i18n.t("policies:folders") : i18n.t("policies:files")}
                </span>
              </div>
            );
          } else if (item.operationType === "SEARCH" && !item.targets) {
            return (
              <div>
                <span>{i18n.t("policies:folders") + "/" + i18n.t("policies:files")}</span>
              </div>
            );
          } else if (item.operationType === "SHARE" && item.source) {
            return (
              <div>
                <Link
                  className="mr-2"
                  href={"https://synergyos.com/" + companyUrl + "/drive/" + item.source.id}
                  title={item.source.name ?? "FOLDER"}
                >
                  {item.source.name ?? "FOLDER"}
                </Link>
              </div>
            );
          } else if (item.targets && item.targets.length > 0) {
            let files = item.targets.filter((t) => {
              return t.modelType === "FILE";
            });
            let folders = item.targets.filter((t) => {
              return t.modelType === "FOLDER";
            });
            if (files.length > 0 && folders.length > 0) {
              return (
                <div>
                  <span
                    id={item.id}
                    title={itemText}
                    onClick={() => this.setState({ calloutTarget: item })}
                    className="cursor-pointer mr-2"
                  >
                    {files.length + folders.length + " " + i18n.t("activity:items")}{" "}
                    <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
                  </span>
                </div>
              );
            } else if (files.length > 1) {
              return (
                <div>
                  <span
                    id={item.id}
                    title={itemText}
                    onClick={() => this.setState({ calloutTarget: item })}
                    className="cursor-pointer"
                  >
                    {files.length + " " + i18n.t("activity:files")}{" "}
                    <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
                  </span>
                </div>
              );
            } else if (folders.length > 1) {
              return (
                <div>
                  <span
                    id={item.id}
                    title={itemText}
                    onClick={() => this.setState({ calloutTarget: item })}
                    className="cursor-pointer"
                  >
                    {folders.length + " " + i18n.t("activity:folders")}{" "}
                    <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
                  </span>
                </div>
              );
            } else if (item.targets[0] && item.targets[0].modelType === "GROUP") {
              return (
                <div>
                  <span title={item.targets[0].name}>{item.targets[0].name}</span>
                </div>
              );
            } else {
              return item.targets.map((target) => {
                if (target.modelType === "USER") {
                  return (
                    <div>
                      <Link href={"mail:" + target.email} title={target.email}>
                        {target.email}
                      </Link>
                    </div>
                  );
                } else if (target.modelType === "FILE" || target.modelType === "COMMENT") {
                  return (
                    <div>
                      <Link
                        className="mr-2"
                        href={"https://synergyos.com/" + companyUrl + "/path/" + target.id}
                        title={target.name ?? "FILE"}
                      >
                        {target.name ?? "FILE"}
                      </Link>
                    </div>
                  );
                } else if (target.modelType === "FOLDER") {
                  return (
                    <div>
                      <Link
                        className="mr-2"
                        href={"https://synergyos.com/" + companyUrl + "/drive/" + target.id}
                        title={target.name ?? "FOLDER"}
                      >
                        {target.name ?? "FOLDER"}
                      </Link>
                    </div>
                  );
                } else if (target.modelType === "REVISION") {
                  return (
                    <div>
                      <Link
                        className="mr-2"
                        href={"https://synergyos.com/" + companyUrl + "/path/" + target.id}
                        title={target.name ?? item.source?.name ?? "FILE"}
                      >
                        {target.name ?? item.source?.name ?? "FILE"}
                      </Link>
                    </div>
                  );
                } else if (target.modelType === "TAG") {
                  return (
                    <div>
                      <Link
                        className="mr-2"
                        href={"https://synergyos.com/" + companyUrl + "/path/" + target.id}
                        title={target.name ?? "FILE"}
                      >
                        {target.name ?? "FILE"}
                      </Link>
                    </div>
                  );
                } else if (target.modelType === "SMART_FOLDER") {
                  return (
                    <div>
                      <span></span>
                    </div>
                  );
                } else if (target.modelType === "ZIP") {
                  return (
                    <div>
                      <span title={target.name}>{target.name ?? "ZIP"}</span>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <span title={messages.UNKNOWN_TARGET.DEFAULT}>{messages.UNKNOWN_TARGET.DEFAULT}</span>
                    </div>
                  );
                }
              });
            }
          } else {
            return (
              <div>
                <span title={itemText || "Unknown"}>{itemText || "Unknown"}</span>
              </div>
            );
          }
        },
      },
      {
        key: "details",
        name: i18n.t("activity:details"),
        fieldName: "details",
        minWidth: 120,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        columnActionsMode: 0,
        onRender: (item: IDocument) => {
          return <ActivityDetails userData={this.props.userData} repoUsers={this.state.repoUsers} item={item} />;
        },
      },
    ];

    this.state = {
      userData: null,
      breadcrumb: [],
      columns: columns,
      items: [],
      calloutTarget: null,
      startIndex: 0,
      indexSize: 50,
      loadingActivity: true,
      isLoadingMoreActivity: false,
      canLoadMore: false,
      searchQuery: [],
      selItems: null,
      showRightPanel: true,
      currentPage: 0,
      repoUsers: [],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    userService.getRepoUsers("ALL", "").then((response) => {
      let repoUsers = response.data;
      repoUsers.groups = [];
      userService.getGroups("ALL").then((response: any) => {
        var groups = response.data;
        groups.forEach((group) => {
          repoUsers.groups.push(group);
        });
        var groupsData = repoUsers.groups;
        for (let i = 0; i < groupsData.length; i++) {
          let groupData = groupsData[i];
          var groupUsers = groupData.users.map((user) => {
            var matchUser = repoUsers.filter((repoUser) => {
              return repoUser.id === user;
            })[0];
            return matchUser;
          });
          if (groupUsers) groupData.users = groupUsers;
        }
        this._isMounted && this.setState({ repoUsers: repoUsers });
        this.setBreadcrumb();
        this._getActivity();
      });
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.userData !== prevProps.userData) {
      userService.getRepoUsers("ALL", "").then((response) => {
        let repoUsers = response.data;
        userService.getGroups("ALL").then((response: any) => {
          var groups = response.data;
          groups.forEach((group) => {
            repoUsers.groups.push(group);
          });
          var groupsData = repoUsers.groups;
          for (let i = 0; i < groupsData.length; i++) {
            let groupData = groupsData[i];
            var groupUsers = groupData.users.map((user) => {
              var matchUser = repoUsers.filter((repoUser) => {
                return repoUser.id === user;
              })[0];
              return matchUser;
            });
            if (groupUsers) groupData.users = groupUsers;
          }
          this._isMounted && this.setState({ repoUsers: repoUsers });
          this.setBreadcrumb();
          this._getActivity();
        });
      });
    }

    if (prevProps.location.search !== this.props.location.search) {
      this._isMounted &&
        this.props.userData &&
        this.setState(
          {
            items: [],
            startIndex: 0,
          },
          () => {
            this.setBreadcrumb();
            this._getActivity();
          }
        );
    }
  }

  private setBreadcrumb() {
    this._isMounted &&
      this.setState({
        breadcrumb: [
          {
            text: "Activity",
            key: "/activity",
            onClick: this.headerRef._onBreadcrumbItemClicked.bind(this),
          },
        ],
      });
  }

  public render() {
    const { columns, items, loadingActivity, isLoadingMoreActivity, canLoadMore } = this.state;

    const callbackFunction = (childData) => {
      this._isMounted && this.setState(childData);
    };
    let companyUrl = this.props.userData?.repository.deploymentDomain.replaceAll(".com", "").replaceAll(".", "-");
    return (
      <Fabric>
        <Header
          ref={(instance: any) => {
            this.headerRef = instance;
          }}
          userData={this.props.userData}
          breadcrumb={this.state.breadcrumb}
          actions="activity"
          getActivity={this._refreshCurrentContent.bind(this)}
          sidebarRef={this.sidebarRef}
          callbackFunction={callbackFunction}
          showRightPanel={this.state.showRightPanel}
        />

        <div className="content-wrap d-flex flex-row">
          <div className="fakeLine"></div>
          <Sidebar
            ref={(instance: any) => {
              this.sidebarRef = instance;
            }}
            userData={this.props.userData}
            searchQuery={this.props.location.search}
          />

          <div className="list mr-auto flex-grow-1">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <ShimmeredDetailsList
                items={items}
                compact={true}
                columns={columns}
                setKey="set"
                isHeaderVisible={true}
                enableShimmer={loadingActivity && !isLoadingMoreActivity}
                constrainMode={ConstrainMode.unconstrained}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                shimmerLines={12}
                selection={this._selection}
              />

              {canLoadMore && items && items.length > 0 ? (
                <div className="load-more text-center p-3 w-100">
                  <PrimaryButton
                    onClick={this._getActivity.bind(this, this.state.currentPage + 1)}
                    text="Load more activity"
                    disabled={isLoadingMoreActivity}
                    className="w-50"
                  />
                  {isLoadingMoreActivity ? <Spinner size={SpinnerSize.xSmall} className="d-inline-block ms-2" /> : null}
                </div>
              ) : null}
            </ScrollablePane>

            {this.state.calloutTarget ? (
              <Callout
                className={"activity-items-callout"}
                gapSpace={0}
                target={`#${this.state.calloutTarget.id}`}
                isBeakVisible={true}
                onDismiss={() => this.setState({ calloutTarget: null })}
                directionalHint={DirectionalHint.bottomLeftEdge}
                setInitialFocus
              >
                <ul className="m-0 p-0" style={{ listStyleType: "none" }}>
                  {this.state.calloutTarget.targets.map((target) => {
                    if (target.modelType === "FOLDER") {
                      return (
                        <li key={target.id} className="mb-2">
                          <Link href={"https://synergyos.com/" + companyUrl + "/drive/" + target.id} title={target.id}>
                            {target.name ?? "Folder"}
                          </Link>
                        </li>
                      );
                    } else if (target.modelType === "FILE") {
                      return (
                        <li key={target.id} className="mb-2">
                          <Link href={"https://synergyos.com/" + companyUrl + "/path/" + target.id} title={target.id}>
                            {target.name ?? "FILE"}
                          </Link>
                        </li>
                      );
                    } else return <li></li>;
                  })}
                </ul>
              </Callout>
            ) : null}

            {this.state.items.length === 0 && !this.state.loadingActivity && !this.state.isLoadingMoreActivity ? (
              <div className="empty-folder-msg p-3">
                <p className="text-center text-secondary mt-5 mx-auto px-3">No activity matches the current filters</p>
              </div>
            ) : null}
          </div>
          <DetailsPanel
            //ref={instance => { this.detailsPanelRef = instance; }}
            userData={this.props.userData}
            repoUsers={this.state.repoUsers}
            //items={ companies }
            showRightPanel={this.state.showRightPanel}
            selItem={this.state.selItems}
            headerRef={this.headerRef}
            _selection={this._selection}
            detailsPanelSection="activity"
            callbackFunction={callbackFunction}
          />
        </div>
      </Fabric>
    );
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);

    localStorage.setItem("dashboardColumns", JSON.stringify(newColumns));

    this._isMounted &&
      this.setState({
        columns: newColumns,
        items: newItems,
      });
  };

  private _getActivity(currentPage = 0) {
    this._isMounted && this.setState({ loadingActivity: true });
    this._isMounted &&
      this.state.items.length > 0 &&
      this.setState({
        isLoadingMoreActivity: true,
      });

    const newSearchQuery = this.props.location.search;
    const params = new URLSearchParams(newSearchQuery);
    var modelQuery = params.get("modelTypes");
    var operationQuery = params.get("operationTypes");
    var userId = params.get("user_id");
    var initialDate: any = params.get("initialDate");
    if (!initialDate) initialDate = new Date(2020, 0, 1).getTime();
    var finalDate: any = params.get("finalDate");
    if (!finalDate) {
      finalDate = new Date(Date.now());
      finalDate.setHours(23, 59, 59, 999);
      finalDate = finalDate.getTime();
    }

    const requestOptions: any = { application: "DRIVE" };
    if (operationQuery) requestOptions.operationTypes = [operationQuery];
    if (modelQuery) requestOptions.modelTypes = [modelQuery];
    if (initialDate) requestOptions.initialDate = initialDate;
    if (finalDate) requestOptions.finalDate = finalDate;
    if (userId) requestOptions.userId = userId;

    userService
      .searchActivity("?page=" + currentPage, requestOptions)
      .then((response: any) => {
        const { repoUsers } = this.state;
        var activity = response.data.content;
        var activityItems = activity.map((data: any) => {
          var messages: any = i18n.t("activityMessages:messages", { returnObjects: true });

          /*
           * Activity message creation. Context changes based on type of operation and type of target item.
           */
          var message = messages.UNKNOWN_OPERATION.DEFAULT;
          if (messages[data.operationType]) {
            if (data.targets && data.targets[0] && messages[data.operationType][data.targets[0].modelType]) {
              message = messages[data.operationType][data.targets[0].modelType];
            } else if (data.source && messages[data.operationType][data.source.modelType]) {
              message = messages[data.operationType][data.source.modelType];
            } else {
              message = messages[data.operationType].DEFAULT;
            }
          }

          var model_objects =
            data.model_objects &&
            data.model_objects.sort((a: any, b: any) => {
              var aType = a.op_related_names || "";
              var bType = b.op_related_names || "";
              var aName = a.name && a.name.toLowerCase();
              var bName = b.name && b.name.toLowerCase();

              if (aType === bType) {
                return aName < bName ? -1 : aName > bName ? 1 : 0;
              } else {
                return aType < bType ? 1 : -1;
              }
            });

          let user: any = repoUsers.find((user: any) => {
            return user.id === data.userId;
          });

          let userFullName = "";
          if (user) userFullName = (user.name || "") + " " + (user.surname || "");

          /*
           * Map target info
           */
          if (data.targets) {
            let targets: any[] = data.targets;
            for (let i in targets) {
              if (targets[i].modelType === "USER") {
                let user: any = repoUsers.find((user: any) => {
                  return user.id === targets[i].id;
                });
                if (user) targets[i] = { ...user, ...targets[i] };
                else targets[i] = messages.UNKNOWN_TARGET.DEFAULT;
              }
            }
          }

          return {
            id: data.id,
            date: data.date,
            dateValue: data.date,
            user: userFullName,
            action: message,
            targets: data.targets,
            source: data.source,
            destination: data.destination,
            item: model_objects,
            operationType: data.operationType,
            message: data.message,
            model_objects: data.model_objects,
            old_2nd_model_objects: data.old_2nd_model_objects,
            searchQuery: data.search_query_map,
          };
        });

        activityItems = activityItems.sort((a: any, b: any) => {
          if (a.dateValue < b.dateValue) return 1;
          if (b.dateValue < a.dateValue) return -1;

          return 0;
        });

        this._isMounted &&
          this.setState((prevState) => {
            const items = [...prevState.items, ...activityItems];
            return { items };
          });

        var canLoadMore = !response.data.last;

        this.setState({
          loadingActivity: false,
          isLoadingMoreActivity: false,
          canLoadMore: canLoadMore,
          currentPage: currentPage,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  private _refreshCurrentContent() {
    this._isMounted &&
      this.setState(
        {
          items: [],
          startIndex: 0,
          canLoadMore: false,
        },
        () => {
          this._getActivity();
        }
      );
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
